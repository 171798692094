@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';
@import '../../../../../styles/mixins/responsive-image';

.bb.bb-donations-header.bb-donations-header-desktop {
  position: relative;
  max-width: 1920px; // TODO: set it as max width for all large containers.
  margin-left: auto;
  margin-right: auto;

  .image-container {
    position: relative;
    min-height: 390px;

    img {
      height: auto;
    }

    .gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-image: linear-gradient(to bottom, rgba(33, 36, 49, 0.3), rgba(33, 36, 49, 0.8));
    }

    .frayed-edge {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  .overlay {
    position: relative;
    width: 100%;
    margin-top: -348px;
    margin-bottom: 20px;

    &.no-switcher {
      margin-top: -260px;
    }

    .container {
      > h1 {
        color: $white;
        margin-bottom: 12px;
        text-align: center;
        text-shadow: 1px 1px 1px $slate-1200;
      }

      > p {
        color: $white;
        font-size: 28px;
        line-height: $line-height-large;
        max-width: 780px;
        margin: auto;
        text-align: center;
        text-shadow: 1px 1px 1px $slate-1200;
      }

      > div {
        margin: 40px 0 20px;
      }

      // don't fade out disabled or loading components
      .disabled {
        opacity: 1;
      }

      .switcher-field {
        // special case to avoid IE11 cropping
        @include media-breakpoint-up(md) {
          max-width: 300px;
        }
      }

      .button-field {
        // overwrite padding for tablet
        @include media-breakpoint-down(lg) {
          padding: 16px;
        }

        &:not(:last-child) {
          margin-right: 24px;
          // reduce inter-element margin for tablet
          @include media-breakpoint-down(lg) {
            margin-right: 12px;
          }
        }
      }

      .button-field-form {
        // conditionally show either the placeholder on desktop or the label
        // (same text as placeholder) on tablet down, to avoid visual cutoff
        input::placeholder {
          @include media-breakpoint-down(xl) {
            font-size: 14px;
          }
          @include media-breakpoint-down(lg) {
            color: transparent;
          }
        }

        label {
          @include media-breakpoint-up(lg) {
            display: none;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      .bottom-description {
        text-align: center;
        color: $pink-800;
        margin: 27px 35px 10px;
      }
    }
  }

  &.with-preselected {
    // Switcher
    .switcher-field {
      padding: 8px 12px;
      max-width: 270px !important;
      .header-three {
        font-size: 20px;
      }
    }

    .switcher-field:not(.active) {
      border: 1px solid $pink-800;
      background-color: $white;

      .header-three {
        color: $pink-800;
      }
      .indicator.not-active {
        border: 1px solid $pink-800;
        opacity: 1;
      }

      &:hover {
        background-color: $pink-1000;
        .header-three {
          color: $white;
        }
      }
    }

    .switcher-field.active {
      background-color: $pink-800;
      .indicator path {
        fill: $pink-800;
      }
    }

    // Money handles
    .button-field {
      padding: 20px 23px 15px 23px;
      border: 1px solid $pink-800;
      p {
        margin-top: 12px;
        margin-bottom: 30px;
      }
    }

    .button-field:not(.selected) {
      background-color: $white;

      > * {
        color: $pink-800;
      }

      button {
        border-color: $pink-800;
      }

      input {
        border-color: $pink-800;
        border-radius: 4px;
      }

      label {
        color: $pink-800 !important;
      }

      &.button-field-form {
        button {
          color: $pink-800;
        }
      }

      &:hover {
        background-color: $pink-800;
        > * {
          color: $white;
        }

        button {
          transition: none;
          color: $pink-800;
          background-color: $white;
        }
      }
    }

    .button-field.selected {
      input {
        border-radius: 4px;
      }

      &:hover {
        background-color: $pink-800;
      }

      button {
        color: $pink-800;
        background-color: $white;
        &:hover {
          border-color: $pink-800;
        }
      }
    }
  }

  @include responsive-image();
  @include hero-dec();
}
